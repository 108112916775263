import React from 'react';
import './Footer.css';


const Footer = () => {
    return (
        <div className = 'footer'>
            centralline.rs <br/>
            ©Copyright 2020 | All Rights Reserved. 
        </div>
    )
}

export {Footer};